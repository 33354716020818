/**
 * Theme for Laithwaites AU-LWNZ site
 */
import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';

export const buildThemeVarsAPACLWNZ = (vars: Pick<Theme, CommonThemeVars>) => {
  const lwPink = '#E2004D';
  const lwPink500 = '#E2004D';
  const lwGrey700 = '#616161';
  const lwUnlimitedMemberPrice = '#009F8E';
  const lwJoinWhite = '#FFFFFF';
  const lwBodyBlack = '#222222';

  const colors = {
    primary1: lwPink,
    textPrimaryBg: lwPink500,
    textSecondaryBg: lwGrey700,
    textUnlimitedPrice: lwUnlimitedMemberPrice,
    lwJoinWhite: lwJoinWhite,
    lwBodyBlack: lwBodyBlack,
  };
  const fonts = {
    sizeH5: '18px',
  };

  const lwThemeVars = buildThemeVarsAPACLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Laithwaites NZ',
    },
    icons,
    colors,
    fonts,
  });
};
export const buildThemeMixinsAPACLWNZ = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  return buildThemeMixinsAPACLW(vars);
};
export const buildThemeWidgetStylesAPACLWNZ = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);
  return buildThemeWidget;
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACLWNZ, buildThemeMixinsAPACLWNZ, buildThemeWidgetStylesAPACLWNZ);

export default createTheme;
